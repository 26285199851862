$(window).load(function () {

  var $pageContent = $("#page-content");
  var $toolbar = $("#toolbarArea");
  var $sidebarAreas = $(".sidebarArea");
  var contentTop = 0;

  var setVariables = function() {
    if ($pageContent.length > 0) {
      contentTop = parseInt(getComputedStyle($pageContent[0]).getPropertyValue("top"));
    }
  };
  setVariables();

  var ticking = false;
  var scrollHandler = function() {
    if(!ticking) {
      window.requestAnimationFrame(function() {
        if ($sidebarAreas.length > 0) {
          var windowScroll = window.scrollY;
          $sidebarAreas.each(function () {
            var $sidebarArea = $(this);
            if (windowScroll >= (contentTop + $toolbar.outerHeight())) {
              $sidebarArea.addClass("sticky");
            } else {
              $sidebarArea.removeClass("sticky");
            }
          });
        }
        ticking = false;
      });
      ticking = true;
    }
  };

  var passiveIfSupported = false;
  try {
    window.addEventListener("test", null, Object.defineProperty({}, "passive", { get: function() { passiveIfSupported = { passive: true }; } }));
  } catch(err) {}
  window.addEventListener("scroll", scrollHandler, passiveIfSupported);
  window.addEventListener("resize", setVariables, passiveIfSupported);
  window.addEventListener("resize", scrollHandler, passiveIfSupported);
  scrollHandler();

});
